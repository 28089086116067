import React from "react";
import {
    Nav
} from "react-bootstrap";

function SocialIconLink({ link, icon }) {
    return (
        <Nav.Item>
            <Nav.Link href={link} target="_blank" rel="noopener noreferrer">
                <i className={ "fab fa-" + icon } />
            </Nav.Link>
        </Nav.Item>
    );
}

export default SocialIconLink;
