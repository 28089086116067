import React from 'react';
import {
    Container,
    Nav
} from "react-bootstrap";
import SocialIconLink from "./components/SocialIconLink";
import Footer from "./components/Footer";

function App() {
    return (
        <React.Fragment>
            <Container fluid className="text-center">
                <h1 className="zenuwpoepjes mt-5">#ZENUWPOEPJES</h1>

                <Nav className="d-flex justify-content-center" style={{ fontSize: "2.5rem" }}>
                    <SocialIconLink icon="instagram" link="https://instagram.com/zenuwpoepjes"/>
                </Nav>

            </Container>
            <Footer/>
        </React.Fragment>
    );
}

export default App;
